<template>
  <div :style="{width}">
    <v-text-field
        :height="height"
        :value="value"
        @input="saveField"
        :rules="validationRules"
        :placeholder="placeholder"
        :append-icon="icon"
        :readonly="readonly"
        :disabled="disabled"
        :class="{rounded: rounded}"
        outlined
        dense
        v-mask="mask"
        @click="onClick"
    />
  </div>
</template>
<script>
import {
  required,
  maxLengthIncluding,
  email,
  specificEmail,
  duplicateValue,
  maxLength,
  phone,
  validateBySumElements,
  validateBySumMoreElements, equalToElementValue, minLength, specialSymbols, allowSpecialSymbols,
} from "@/utils/validation-rules.js";
import {mask} from "vue-the-mask"

export default {
  directives: {
    mask: (el, binding) => {
      if (binding.value === '*') {
        return;
      }

      mask(el, binding);
    },
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      required: false,
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
    width: {
      type: String,
      default: 'auto',
    },
    height: {
      type: String,
      default: 'auto',
    },
    icon: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    mask: {
      type: String,
      default: '*',
    },
  },
  computed: {
    allBuilderElements() {
      return this.$store.getters["insuranceModule/builderElementsModule/getAllBuilderElements"];
    },
    validationRules() {
      const rules = [];

      if (this.validation.maxLength) {
        rules.push((val) => maxLength(val, {length: this.validation.maxLength}))
      }

      if (this.validation.maxLengthIncluding) {
        rules.push((val, message = this.validation.message || "Max length for this field is ") => maxLengthIncluding(val, {length: this.validation.maxLengthIncluding}, message))
      }

      if (this.validation.required && this.validation.required !== '0') {
        rules.push((val, message = this.validation.message || 'Pflichtfeld') => required(val, message));
      }

      if (this.validation.email) {
        rules.push(email)
      }

      if (this.validation.specificEmail) {
        rules.push(specificEmail)
      }

      if (this.validation.duplicateValue) {
        rules.push((val) => duplicateValue(val, this.validation.duplicateValue.compareTo, this.validation.duplicateValue.message))
      }

      if (this.validation.phone) {
        rules.push(phone)
      }

      if (this.validation.isCustom) {
        rules.push(this.validation.customValidationFunction)
      }

      if (this.validation.bySum) {
        rules.push((val, message = this.validation.bySum.message || 'Pflichtfeld') =>
            validateBySumElements(
                val,
                this.validation.bySum.fieldNames,
                this.validation.bySum.operation,
                this.validation.bySum.targetValue,
                this.allBuilderElements,
                this.validation.bySum.passiveElement,
                message
            )
        )
      }

      if (this.validation.bySumMore) {
        rules.push((val, message = this.validation.bySumMore.message || 'Pflichtfeld') =>
          validateBySumMoreElements(
            val,
            this.validation.bySumMore.fieldNames,
            this.validation.bySumMore.operation,
            this.validation.bySumMore.targetValue,
            this.allBuilderElements,
            this.validation.bySumMore.passiveElement,
            message
          )
        )
      }

      if (this.validation.equalTo && this.validation.equalTo.elementId) {
        rules.push(
            (val, message = this.validation.equalTo.message || 'Error') =>
                equalToElementValue(val, this.validation.equalTo.elementId, this.allBuilderElements, message)
        );
      }

      if (this.validation.minLength) {
        rules.push((val, message = this.validation.minLengthMessage || "Min length for this field is ") => minLength(val, {length: this.validation.minLength}, message))
      }

      if (this.validation.bySpecialSymbols) {
        rules.push((val) => specialSymbols(val, {symbols: this.validation.bySpecialSymbols.symbols}))
      }

      if (this.validation.allowSpecialSymbols) {
        rules.push(allowSpecialSymbols)
      }

      return rules;
    }
  },
  methods: {
    saveField(value) {
      this.$emit('saveField', value, this.name)
      this.$emit('input', value);
    },
    onClick() {
      this.$emit('click');
    },
  }
}
</script>