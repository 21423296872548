<template>
  <v-app-bar
      class="header-border header-block"
      color="var(--white-bg)"
      height="auto"
      outlined
  >
    <div class="logo-block">
      <img class="app-logo" src="/img/Allianz.svg" alt="Allianz logo"/>
    </div>

    <div class="header-info">
      <div class="btn-block" v-if="showNavigation">
        <orange-btn
            class="px-4"
            v-if="authUserIsAdmin || authUserIsStatistic"
            @click="toAdminPanel"
        >
          Admin Panel
        </orange-btn>

        <white-btn
            class="ml-2 px-4"
            v-if="isShowNewInsuranceButtonComputed"
            @click="toInsuranceCreate"
        >
          Neues Angebot
        </white-btn>

        <blue-btn
            class="ml-2 px-4"
            @click="toInsuranceList"
        >
          Gerechnete Angebote
        </blue-btn>
      </div>

      <div v-if="showUserInfo" class="user-block">
        <user-info/>
      </div>

      <div v-if="isUserAuthorized && showIsUserAuthorized" class="ma-auto">
        <logout-icon @click="logoutClick" />
      </div>

      <div v-if="isShowStagingUpdatedAt" class="pl-3 ma-auto staging-updated-at">Stand vom:{{ stagingUpdatedAt }}</div>

      <div v-if="isShowInsuranceId" class="pl-3 ma-auto insurance-id-block">Vorgang:{{ insuranceId }}</div>

      <div v-if="isTestEnv" class="pl-3 ma-auto insurance-id-block">
        <switch-field
          name="isTestProcess"
          :value="isUserTestProcess"
          @saveField="saveIsTestProcess"
        />
      </div>
    </div>

  </v-app-bar>
</template>
<script>
import UserInfo from "./header/UserInfo.vue";
import BlueBtn from "../../components/common/buttons/BlueBtn.vue";
import WhiteBtn from "../../components/common/buttons/WhiteBtn.vue";
import {routeNames} from "@/router/route-names.js";
import OrangeBtn from "../../components/common/buttons/OrangeBtn.vue";
import LogoutIcon from "@/ui/components/common/icons/LogoutIcon.vue";
import {isEnvStaging, isEnvTest, isShowNewInsuranceButton} from "@/plugins/env";
import SwitchField from "@/ui/components/common/fields/SwitchField.vue";


export default {
  components: {LogoutIcon, OrangeBtn, WhiteBtn, BlueBtn, UserInfo, SwitchField},
  props: {
    showNavigation: {
      type: Boolean,
      default: true,
    },
    showUserInfo: {
      type: Boolean,
      default: true,
    },
    showIsUserAuthorized: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    authUserIsAdmin() {
      return this.$store.getters.authUserIsAdmin
    },

    authUserIsStatistic() {
      return this.$store.getters.authUserIsStatistic
    },

    isUserAuthorized() {
      return this.$store.getters.isUserAuthorized;
    },
    isShowStagingUpdatedAt() {
      return isEnvStaging()
    },
    stagingUpdatedAt() {
      return this.$store.getters["insuranceModule/dashboardInfoModule/getStagingUpdatedAtElement"].value ?? ''
    },
    insuranceId() {
      return this.$store.getters["insuranceModule/getInsuranceId"];
    },
    isShowInsuranceId() {
      return (this.$route.name === routeNames.insuranceShow && this.insuranceId)
    },
    isTestEnv() {
      return isEnvTest()
    },
    isUserTestProcess() {
      return this.$store.getters.isTestProcess;
    },
    isShowNewInsuranceButtonComputed() {
      if (isShowNewInsuranceButton()) {
        return true;
      }

      return this.authUserIsAdmin;
    },
  },
  async created() {
    await this.getStagingUpdatedAt()
  },
  methods: {
    async logoutClick() {
      await this.$store.dispatch('logout');
    },

    toAdminPanel() {
      this.$router.push({name: routeNames.admin.dashboard})
    },

    toInsuranceCreate() {
      this.$router.push({name: routeNames.insuranceCreate})
    },

    toInsuranceList() {
      this.$router.push({name: routeNames.insuranceList})
    },

    async getStagingUpdatedAt() {
      if (isEnvStaging()) {
        return this.$store.dispatch('insuranceModule/dashboardInfoModule/getStagingUpdatedAtElement')
      }
    },

    async saveIsTestProcess(val) {
      await this.$store.dispatch('update', {is_test_process: val});
    },
  }
}
</script>
<style scoped>
.header-block {
  padding-top: 7px;
}

.logo-block {
  padding-top: 5px;
}

.header-info {
  display: flex;
  width: 100%;
  margin-left: 44px;
}

.btn-block {
  padding-top: 4px;
  width: 75%;
  justify-content: flex-end;
  display: flex;
  margin: auto;
}

.user-block {
  width: 200px;
  margin-left: 3%;
}

.app-logo {
  width: 127px;
}

.header-border {
  border-bottom: none;
  box-shadow: none !important;
}

.insurance-id-block {
  width: 190px;
}
.staging-updated-at {
  width: 250px;
}
</style>
