import {serverApi} from "@/api/server/server-api.js";
import {Insurance} from "@/models/insurance.js";
import {InsuranceType} from "@/models/insurance-type.js";
import {InsuranceStatus} from "@/models/insurance-status.js";
import {builderElementsModule} from "@/store/modules/builder-elements-module.js";
import {builderElementRelationsModule} from "@/store/modules/builder-element-relations-module.js";
import {insuranceBuildersModule} from "@/store/modules/insurance-builders-module.js";
import {insurancePriceModule} from "@/store/modules/insurance-price-module.js";
import {insuranceNavigationModule} from "@/store/modules/insurance-navigation-module.js";
import {SimpleInsurance} from "@/models/simple-insurance.js";
import {insurancePriceAddonModule} from "@/store/modules/insurance-price-addon-module.js";
import {insurancePoliceModule} from "@/store/modules/insurance-police-module.js";
import {expiringTokenModule} from "@/store/modules/expiring-token-module.js";
import {insuranceFinishedModule} from "@/store/modules/insurance-finished-module.js";
import {apiDataModule} from "@/store/modules/api-data-module.js";
import {dashboardInfoModule} from "@/store/modules/dashboard-info-module";

export const insuranceModule = {
    namespaced: true,

    modules: {
        builderElementsModule,
        builderElementRelationsModule,
        insuranceBuildersModule,
        insurancePriceModule,
        insuranceNavigationModule,
        insurancePriceAddonModule,
        insurancePoliceModule,
        expiringTokenModule,
        insuranceFinishedModule,
        apiDataModule,
        dashboardInfoModule,
    },

    state: {
        insurance: Insurance.empty(),

        preloader: false,

        processPreloader: false,
    },

    getters: {
        getPreloader(state) {
            return state.preloader;
        },

        getProcessPreloader(state) {
            return state.processPreloader;
        },

        getInsurance(state) {
            return state.insurance;
        },

        getInsuranceStatus(state) {
            return state.insurance.status;
        },

        getInsuranceName(state) {
            return state.insurance.name;
        },

        getInsuranceType(state) {
            return state.insurance.type;
        },

        getInsuranceId(state) {
            return state.insurance.id
        },

        getIsPriceGot(state) {
            return Boolean(state.insurance.is_got_price);
        },

        getIsCustomerConfirmed(state) {
            return Boolean(state.insurance.is_customer_confirmed);
        },

        getFinishedAt(state) {
            return state.insurance.finished_at;
        },
    },

    mutations: {
        setInsuranceName(state, name) {
            state.insurance.name = name;
        },

        setInsurance(state, insurance) {
            state.insurance = insurance;
            state.insurance.status = insurance.status
        },

        setInsuranceStatus(state, status) {
            if (!(status instanceof InsuranceStatus)) {
                throw Error("Insurance status must be type of InsuranceStatus")
            }

            state.insurance.status = status;
        },

        setIsPriceGot(state, isPriceGot) {
            state.insurance.is_got_price = isPriceGot;
        },

        setFinishedAt(state, finishedAt) {
            state.insurance.finished_at = finishedAt;
        },

        setInsuranceType(state, insuranceType) {
            if (!(insuranceType instanceof InsuranceType)) {
                throw Error("Insurance type must be type of InsuranceType")
            }

            state.insurance.type = insuranceType;
        },

        showPreloader(state) {
            state.preloader = true;
        },

        hidePreloader(state) {
            state.preloader = false;
        },

        showProcessPreloader(state) {
            state.processPreloader = true;
        },

        hideProcessPreloader(state) {
            state.processPreloader = false;
        },
    },

    actions: {
        resetInsuranceData: async (context, {insurance}) => {
            await context.dispatch('insuranceBuildersModule/resetInsuranceBuildersData', {
                insuranceBuilders: insurance.type.insuranceBuilders
            });
        },

        getInsuranceTypes: async () => {
            const {data} = await serverApi.insurance.insuranceTypes();

            return data.insuranceTypes.map((elem) => new InsuranceType(elem));
        },

        createInsurance: async (context, {insuranceType, insuranceName}) => {
            try {
                const {data} = await serverApi.insurance.create(insuranceType.id, insuranceName)

                return {
                    success: data.success,
                    insuranceId: data.insuranceId,
                }
            } catch {
                return {
                    success: false,
                    insuranceId: 0,
                }
            }
        },

        showInsurance: async (context, {insuranceId}) => {
            try {
                const {data} = await serverApi.insurance.show(insuranceId);

                context.commit('setFinishedAt', data.insurance.finished_at);

                return new Insurance(data.insurance);
            }
           catch {
                return Insurance.empty();
           }
        },

        deleteInsurance: async (context, {insuranceId}) => {
            const {data} = await serverApi.insurance.delete(insuranceId)

            return data.success;
        },

        insuranceList: async (context, {perPage, page, search}) => {
            const {data} = await serverApi.insurance.list({perPage, page, search})

            return {
                insurances: data.insurances.map((elem) => new SimpleInsurance(elem)),
                lastPage: data.lastPage,
            };
        },

        statusRiskFinished: async (context) => {
            const insurance = context.state.insurance;
            const {data} = await serverApi.insurance.statusRiskFinished(insurance.id);

            if (data.success) {
                context.commit('setInsuranceStatus', new InsuranceStatus(data.status));
            }
        },

        statusDraft: async (context) => {
            const insurance = context.state.insurance;
            const {data} = await serverApi.insurance.statusDraft(insurance.id);

            if (data.success) {
                context.commit('setInsuranceStatus', new InsuranceStatus(data.status));
            }
        },

        statusCustomer: async (context) => {
            const insurance = context.state.insurance;
            const {data} = await serverApi.insurance.statusCustomer(insurance.id);

            if(data.success) {
                context.commit('setInsuranceStatus', new InsuranceStatus(data.status))
            }
        },

        statusPolice: async (context) => {
            const insurance = context.state.insurance;
            const {data} = await serverApi.insurance.statusPolice(insurance.id);

            if(data.success) {
                context.commit('setInsuranceStatus', new InsuranceStatus(data.status))
            }
        },

        statusInsuranceFinished: async (context) => {
            const insurance = context.state.insurance;
            const {data} = await serverApi.insurance.statusInsuranceFinished(insurance.id);

            if(data.success) {
                context.commit('setInsuranceStatus', new InsuranceStatus(data.status))

                context.commit('setFinishedAt', data.finished_at)
            }
        },

        statusApplicationSubmitted: async (context) => {
            const insurance = context.state.insurance;
            await serverApi.insurance.applicationSubmitted(insurance.id);
        },

        dataAcquisitionForModulation: async (context) => {
            const insurance = context.state.insurance;
            await serverApi.insurance.dataAcquisitionForModulation(insurance.id);
        },

        emptyPrice: async (context) => {
            const insurance = context.state.insurance;
            await serverApi.insurance.emptyPrice(insurance.id);
        },

        statusInsuranceRiskStarted: async (context) => {
            const insurance = context.state.insurance;
            const {data} = await serverApi.insurance.statusInsuranceRiskStarted(insurance.id);

            if(data.success) {
                context.commit('setInsuranceStatus', new InsuranceStatus(data.status))
            }
        },

        statusFromFinishToPolice: async (context) => {
            const insurance = context.state.insurance;
            const {data} = await serverApi.insurance.statusFromFinishToPolice(insurance.id);

            if(data.success) {
                context.commit('setInsuranceStatus', new InsuranceStatus(data.status))

                context.commit('setFinishedAt', data.finished_at)
            }
        },

        statusPoliceStep: async (context) => {
            const insurance = context.state.insurance;
            await serverApi.insurance.statusDeterminePoliceStatus(insurance.id);
        },

        runInsuranceRelations: async (context) => {
            const insuranceId = context.getters.getInsuranceId;
            const {data} = await serverApi.insurance.runInsuranceRelations(insuranceId);

            return data.success
        },

        runPolicyStep: async (context) => {
            const data = {
                success: true,
                status: {name: "police_step"}
            };

            context.commit('setInsuranceStatus', new InsuranceStatus(data.status));

            return data.success
        },

        runInsurancePriceAddonRelations: async (context) => {
            const insuranceId = context.getters.getInsuranceId;

            const {data} = await serverApi.insurance.runInsurancePriceAddonRelations(insuranceId);

            return data.success
        },
        sendXml: async (context, insuranceId = null) => {
            const id = insuranceId ? insuranceId : context.state.insurance.id;

            const {data} = await serverApi.insurance.sendXml(id);

            return data.success
        },
        sendBigXml: async (context, insuranceId = null) => {
            const id = insuranceId ? insuranceId : context.state.insurance.id;

            const {data} = await serverApi.insurance.sendBigXml(id);

            return data.success
        },
        setRiskData: async (context, {insuranceId}) => {
            await serverApi.insurance.setRiskData(insuranceId);
        },
        setRisk2Data: async (context, {insuranceId}) => {
            await serverApi.insurance.setRisk2Data(insuranceId);
        },
        setCustomerData: async (context, {insuranceId}) => {
            await serverApi.insurance.setCustomerData(insuranceId);
        },
    }
}