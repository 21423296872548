
export const builderElementRelationActionsEnum = {
    SET_STATIC_VALUE: 'Set static value',
    SET_STATIC_VALUE_NOT_SKIP: 'Set static value, not skip',
    SET_LABEL_TEXT: 'Set label text',
    SET_BUILDER_ELEMENT_VALUE: 'Set builder element value',
    SET_BUILDER_ELEMENT_DATE_WITH_DATE_PARAMS: 'Set builder element date with date params',
    MARK_SELECTED: 'Mark selected',
    MARK_UNSELECTED: 'Mark unselected',
    HIDE: 'Hide element',
    SHOW: 'Show element',
    SET_DISABLED: 'Set disabled',
    SET_ENABLED: 'Set enabled',
    SET_CURRENT_DATE: "Set current date",
    ADD_EMAIL_VALIDATION: "Add email validation",
    ADD_SPECIFIC_EMAIL_VALIDATION: "Add specific email validation",
    MIN_VALUE_VALIDATION: "Add min value validation",
    MAX_VALUE_VALIDATION: "Add max value validation",
    DATE_FIELD_VALIDATION_BY_FIELD: "Date field validation by field",
    ADD_PHONE_VALIDATION: "Add phone validation",
    SET_VALIDATION_BY_SUM: "Set validation by sum",
    SET_EMPTY_VALIDATION: "Set empty validation",
    SET_VALIDATION_BY_SUM_MORE: "Set validation by sum more",
    SET_VALIDATION_EQUAL_TO_ELEMENT_VALUE: 'Set validation is equal to element value',
    ADD_INCORRECT_VALUE_VALIDATION: 'Add incorrect value validation',
    ADD_SPECIAL_SYMBOLS_VALIDATION: 'Add special symbols validation',
    ALLOW_SPECIAL_SYMBOLS_VALIDATION: 'Allow special symbols validation',
    //TODO: complete or remove multiple duplicate
    MULTIPLE_DUPLICATE: 'Multiple duplicate',
    SUM: 'Sum',
    REQUIRED: 'Required',
    FIND_CITY: 'Find city',
    REPEAT_BY_ELEMENT: 'Repeat by element',
    SET_REPEATED_VALUE_BY_ELEMENT: 'Set value in repeated element',
    SET_SELECT_OPTIONS: 'Set select options',
    REPEAT_BY_CHECKBOXES_COUNT: "Repeat by selected checkboxes count",
    SHOW_POPUP: "Show popup",
    HIDE_POPUP: "Hide popup",
    SET_PRICE_TAB_VALUE: 'Set value from price tab',
    PREFILL_DATE_FROM_DURATION_PRICE_TAB: 'Prefill date duration price tab',
    LINK_FIELDS: 'Link field value',
    SET_SUM_BY_FIELDS: 'Set sum of fields to the value',
    SPECIAL_DUPLICATE_WITH_COPY: "Special duplicate with copy",
    SPECIAL_COPY_DATA: "Special copy data",
    DELETE_DUPLICATED: "Delete duplicated elements",
    REPEAT_BY_DUPLICATED_ELEMENT: "Repeat by duplicated element",
    SET_IBAN_DATA: 'Set iban data',
}

export const builderElementRelationConditionEnum = {
    ALWAYS: 'Always',
    EQUAL_TO: 'Equal to',
    NOT_EQUAL_TO: 'Not equal to',
    ELEMENT_SELECTED: 'Element selected',
    ELEMENT_UNSELECTED: 'Element unselected',
    EQUAL_TO_BUILDER_ELEMENT: "Equal to builder element value",
    HAVE_ANY_VALUE: 'Have any value',
    IS_EMPTY: 'Is empty',
    IS_MORE_THEN: 'Is more than',
    IS_LESS_THEN: 'Is less than',
    IS_MORE_OR_EQUAL_THEN_YEARS_IN_THE_PAST: 'Is more or equal than (x) years in the past',
    IS_LESS_OR_EQUAL_THEN_YEARS_IN_THE_PAST: 'Is less or equal than (x) years in the past',
    IS_MORE_THEN_YEARS_IN_THE_PAST: 'Is more than (x) years in the past',
    IS_LESS_THEN_YEARS_IN_THE_PAST: 'Is less than (x) years in the past',
    SUM_BY_FIELDS_MORE_THAN: 'Sum by fields is more than',
    SUM_BY_FIELDS_CONDITION: 'Sum by fields condition',
    USER_API_ROLE_EQUAL_TO: 'User api_role equal to',
    IS_IN_DATE_RANGE: 'Is value in date range',
    IS_SHOWN: 'Is shown',
    IS_NOT_SHOWN: 'Is not shown',
    COMPARE_PRICE_DATA: 'Compare insurance price data',
    VALUE_IN_LIST: 'Value is in the list',
    VALUE_NOT_IN_LIST: 'Value is not in the list',
}

export const RUN_ON_ENUM = {
    TRUE: 1,
    FALSE: 0,
}

export const staticFieldTitles = {
    [builderElementRelationActionsEnum.SET_STATIC_VALUE]: 'Value',
    [builderElementRelationActionsEnum.SET_LABEL_TEXT]: 'Label text',
    [builderElementRelationActionsEnum.MIN_VALUE_VALIDATION]: 'Min value',
    [builderElementRelationActionsEnum.MAX_VALUE_VALIDATION]: 'Max value',
    [builderElementRelationActionsEnum.REQUIRED]: 'Error message for required',
    [builderElementRelationActionsEnum.SET_SUM_BY_FIELDS]: 'Field names',
    [builderElementRelationActionsEnum.MULTIPLE_DUPLICATE]: 'Title field name',
    [builderElementRelationActionsEnum.SPECIAL_DUPLICATE_WITH_COPY]: 'Options',
    [builderElementRelationActionsEnum.SET_VALIDATION_EQUAL_TO_ELEMENT_VALUE]: 'Error message',
    [builderElementRelationActionsEnum.ADD_INCORRECT_VALUE_VALIDATION]: 'Error message',
}