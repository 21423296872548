<template>
  <div>
    <v-row>
      <v-col cols="3">
        <p class="app-text app-text__bold">{{ barName }}</p>
      </v-col>
      <v-col cols="9">
        <div class="progress-bar__block">
          <div
              v-for="(checkpointMethod, key) in checkpointObject"
              :key="key"
              class="progress-bar"
          >
            <div v-if="key <= selectedItem && !disabledItems.includes(parseInt(key))">
              <v-tooltip bottom color="blue">
                <template v-slot:activator="{ on }" >
                  <div class="checkpoint"
                       :class="{active: (key <= selectedItem && !disabledItems.includes(parseInt(key)) )}"
                       @click="onClickCheckpoint(key, checkpointMethod)" v-on="on"></div>
                </template>
                <span>{{ tooltips[key] }}</span>
              </v-tooltip>
            </div>
            <div v-else>
              <div class="checkpoint"
                   :class="{active: (key <= selectedItem && !disabledItems.includes(parseInt(key)) )}"
                   @click="onClickCheckpoint(key, checkpointMethod)"></div>
            </div>

          </div>
        </div>
        <div>
          <div class="line"></div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
    },
    selectedItem: {
      type: Number,
      default: 1,
    },
    disabledItems: {
      type: [],
      default: () => [],
      required: false,
    },
    isEmptyPrice: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    checkpointObject() {
      return {1: this.openRiskTab, 2: this.openPriceTab, 3: this.openCustomerTab, 4: this.openPoliceTab, 5: this.openFinishTab}
    },
    insuranceId() {
      return this.$store.getters["insuranceModule/getInsuranceId"];
    },
    insurancePrice() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getInsurancePrice"]
    },
    tooltips() {
      return {
        1: 'Risikoerfassung',
        2: this.isEmptyPrice ? 'Datenerfassung für Aussteuerung' : 'Vorschlag',
        3: 'Antragsdatenerfassung',
        4: 'Antragsstellungsverfahren',
        5: 'Status Antragsabschluss',
      }
    },
    insurance() {
      return this.$store.getters["insuranceModule/getInsurance"];
    },
    barName() {
      if (this.insurance.finished_at) {
        return 'Antrag gestellt'
      }

      return this.name
    },
  },
  methods: {
    async onClickCheckpoint(key, checkpointMethod) {
      if (this.selectedItem < key || this.disabledItems.includes(parseInt(key))) {
        return
      }

      this.$store.commit('insuranceModule/showPreloader')
      await checkpointMethod()
      this.$store.commit('insuranceModule/hidePreloader')
    },
    async openRiskTab() {
      await this.statusInsuranceRiskStarted()
    },
    async openPriceTab() {
      await this.runInsuranceRelations();

      if (!await this.checkOnEmptyPrice(this.insuranceId)) {
        await this.riskFinished()
      }
    },
    async openCustomerTab() {
      await this.statusCustomer()
    },
    async openPoliceTab() {
      if (this.insurancePrice.getIsNpopt()) {
        return
      }
      await this.statusPolice()
    },
    async openFinishTab() {
      await this.statusInsuranceFinished()
    },

    async statusDraft() {
      await this.$store.dispatch('insuranceModule/statusDraft')
    },
    async riskFinished() {
      await this.$store.dispatch('insuranceModule/statusRiskFinished')
    },
    checkOnEmptyPrice(insuranceId) {
      return this.$store.dispatch('insuranceModule/insurancePriceModule/checkEmptyPrice', {
        insuranceId,
      })
    },
    async runInsuranceRelations() {
      await this.$store.dispatch('insuranceModule/runInsuranceRelations');
    },
    async statusCustomer() {
      await this.$store.dispatch('insuranceModule/statusCustomer')
    },
    async statusPolice() {
      await this.$store.dispatch('insuranceModule/statusPolice')
    },
    async statusInsuranceFinished() {
      await this.$store.dispatch('insuranceModule/statusInsuranceFinished')
    },
    async statusInsuranceRiskStarted() {
      await this.$store.dispatch('insuranceModule/statusInsuranceRiskStarted')
    },
  },
}
</script>
<style scoped>
.line {
  height: 1px;
  width: 98%;
  top: -13px;
  position: relative;
  background: var(--gray);
}

.checkpoint {
  background: var(--white);
  border: 1px solid var(--gray);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  z-index: 2;
}

.checkpoint.active {
  background: var(--light-blue);
}

.progress-bar__block {
  display: flex;
  width: 100%;
  clear: left;
  justify-content: space-between;
  padding-top: 8px;
}
.progress-bar {
  z-index: 3;
}

.checkpoint.active {
  cursor: pointer;
}
</style>