<template>
  <div>
    <div class="pl-5 pr-5 app-bg-white mt-8 pb-5">
      <h3 class="pb-2 pt-7 app-text__bold">Infopaket und Antrag als Download</h3>
      <v-row>
        <v-col cols="2">
          <orange-btn
              text
              color="primary"
              @click="onClickInfoPackageAsPdf"
          >
            Infopaket als PDF
          </orange-btn>
        </v-col>
        <v-col cols="2" class="ml-8">
          <orange-btn
              class="orange-btn"
              text
              color="primary"
              :disabled="isApplicationAsPdfBtnDisabled"
              @click="onClickApplicationAsPdf"
          >
            Antrag als PDF
          </orange-btn>
        </v-col>
      </v-row>
    </div>
    <v-form
        :ref="emailFormName"
    >
      <div class="pl-5 pr-5 app-bg-white mt-5 pb-5">
        <h3 class="pt-5 app-text__bold">Infopaket und Antrag als E-Mail</h3>
        <v-row v-if="isEstateAgent" class="py-2">
          <v-col class="pt-2" cols="3">
            <div class="pt-2">Vermittler E-Mail-Adresse:</div>
          </v-col>
          <v-col class="pt-2 pl-5">
            <text-field
                width="276px"
                placeholder="Bitte eintragen"
                :name="dataFieldKeys.managerEmail"
                :value="managerEmail"
                :validation="{required: true, email: true}"
                :disabled="isDisableAllInputs"
            />
          </v-col>
        </v-row>
        <v-row class="py-2">
          <v-col class="pt-2" cols="3">
            <div class="pt-2">Kunden E-Mail-Adresse für den Versand:</div>
          </v-col>
          <v-col class="pt-2 pl-5">
            <text-field
                width="276px"
                placeholder="Bitte eintragen"
                :name="dataFieldKeys.infoPackEmail"
                v-model="infoPackEmail"
                :validation="infoPackEmailValidation"
                :disabled="isDisableAllInputs"
            />
          </v-col>
        </v-row>
        <v-row class="pb-2">
          <v-col class="pt-2" cols="3">
            <div class="pt-2">Wiederholung E-Mail-Adresse:</div>
          </v-col>
          <v-col class="pt-2 pl-5">
            <text-field
                name="duplicate-email"
                width="276px"
                placeholder="Bitte eintragen"
                :validation="{required: true, duplicateValue: {compareTo: this.infoPackEmail, message: 'Wiederholung E-Mail-Adresse!'}}"
                :disabled="isDisableAllInputs"
                v-model="duplicateEmail"
            />
          </v-col>
        </v-row>
        <v-row class="pb-2">
          <checkbox
              text="Der Kunde ist mit dem Versand an die oben stehende E-Mail-Adresse einverstanden. Die Kommunikationsdaten wurden nochmals überprüft."
              :disabled="isDisableAllInputs"
              :value="sendEmailCheckboxValue"
              :validation="sendEmailCheckboxValidation"
              @saveField="onChangeSendEmailCheckboxValue"
          />
        </v-row>
        <orange-btn
            text
            color="primary"
            @click="onClickInfoPackageAsEmail"
        >
          Infopaket und Antrag per E-Mail anfordern
        </orange-btn>
      </div>
    </v-form>
    <v-form
        :ref="fileFormName"
    >
      <div class="pl-5 pr-5 app-bg-white mt-8">
        <h3 class="app-text__bold pt-5 pb-2">Bitte laden Sie den unterzeichneten Antrag hoch</h3>

        <v-row class="pt-5 pb-10">
          <div>
            <div v-for="(currentFile, index) in files" :key="'file' + index" class="d-flex justify-space-between" data-type-builder="duplicate">
              <file-field
                  name="file"
                  :validation="fileValidation"
                  :is-readonly="true"
                  :no-events="false"
                  :disabled="isDisableAllInputs"
                  :value="currentFile.name"
                  :is-document="true"
                  @saveFile="(file) => {files[index] = file;}"
              />

              <delete-icon
                v-if="isDuplicate(index)"
                class="duplicate-btn"
                size="40px"
                :disabled="isDisableAllInputs"
                @click="onClickRemoveDuplicate(index)"
              />
              <add-icon
                v-else
                class="duplicate-btn"
                size="40px"
                :disabled="isDisableAllInputs"
                @click="addFile"
              />
            </div>
          </div>
        </v-row>
      </div>
    </v-form>

    <v-row class="pb-2">
      <v-col class="pt-2">
        <toggle-block
            header="Notizen für den Innendienst"
            headerCssClasses="app-text__bold"
        >
          <div class="px-4 pb-4">
            Sie können dieses Feld nutzen, sofern Sie noch Anmerkungen für den Innendienst zum Antrag haben.<br><br>
            <b>Bitte beachten Sie:</b><br>
            Abweichungen in der Deckung oder dem Preis der Produkte sind nicht möglich.<br>
            Die individuelle manuelle Bearbeitung Ihres Anliegens kann einige Zeit in Anspruch nehmen.
            <v-row>
              <v-col class="pt-5 pb-5">
                <text-area-field
                    placeholder="Bitte eintragen"
                    :name="dataFieldKeys.notes"
                    :value="notes"
                    :disabled="isDisableAllInputs"
                    @saveField="saveField"
                />
              </v-col>
            </v-row>
          </div>
        </toggle-block>
      </v-col>
    </v-row>

    <insurance-step-wrapper
        @prevButtonClick="onClickPrev"
        @nextButtonClick="onClickNext"
        :next-button-text="nextBtnName"
    >
    </insurance-step-wrapper>

  </div>
</template>

<script>
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue"
import TextField from "@/ui/components/common/fields/TextField.vue"
import FileField from "@/ui/components/common/fields/FileField.vue"
import InsuranceStepWrapper from "@/ui/components/insurance/insurance-wrappers/InsuranceStepWrapper.vue"
import {STEP3_OPTIONS_BUTTON_NAMES} from "@/utils/fields/insurance-police-step-fields.js"
import {DATA_FIELD_KEYS} from "@/utils/fields/insurance-police-field-keys.js"
import {notificationService} from "@/utils/services/notification-service.js"
import {dateService, SQL_FORMAT} from "@/utils/services/date-service.js"
import Checkbox from "@/ui/components/common/fields/Checkbox.vue"
import {serverApi} from "@/api/server/server-api.js"
import {generateDownloadLink} from "@/utils/services/file-service.js"
import TextAreaField from "@/ui/components/common/fields/TextAreaField.vue"
import ToggleBlock from "@/ui/components/common/blocks/ToggleBlock.vue";
import DeleteIcon from "@/ui/components/common/icons/DeleteIcon";
import AddIcon from "@/ui/components/common/icons/AddIcon";
import {AppFile} from "@/models/app-file";

export default {
  components: {
    OrangeBtn,
    TextField,
    FileField,
    InsuranceStepWrapper,
    Checkbox,
    TextAreaField,
    ToggleBlock,
    DeleteIcon,
    AddIcon,
  },
  props: {
    isEstateAgent: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    dataFieldKeys() {
      return DATA_FIELD_KEYS
    },
    insurancePolice() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getInsurancePolice"]
    },
    emailForm() {
      return this.$refs[this.emailFormName];
    },
    emailFormName() {
      return 'policeStep3Option2email';
    },
    fileForm() {
      return this.$refs[this.fileFormName];
    },
    fileFormName() {
      return 'policeStep3Option2file';
    },
    managerEmail() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.managerEmail]
    },
    isDisableAllInputs() {
      return this.isInsuranceFinishedAt
    },
    notes() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.notes]
    },
    isInsuranceFinishedAt() {
      return Boolean(this.$store.getters["insuranceModule/getFinishedAt"])
    },
    isRequiredEmails() {
      return this.sendEmailCheckboxValue === '1';
    },
    infoPackEmailValidation() {
      return {
        required: this.isRequiredEmails,
        email: Boolean(this.infoPackEmail),
      };
    },
    sendEmailCheckboxValidation() {
      return {required: !this.isDisableAllInputs}
    },
  },
  data: () => ({
    isApplicationAsPdfBtnDisabled: true,
    file: null,
    files: [],
    duplicateEmail: '',
    nextBtnName: STEP3_OPTIONS_BUTTON_NAMES.lock,
    fileValidation: {required: true, maxSize: 5},// maxSize in MiB
    sendEmailCheckboxValue: '0',
    infoPackEmail: '',
  }),
  created() {
    this.changeNextBtnName()

    this.initFiles()

    this.changeValidation()

    this.infoPackEmail = this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.infoPackEmail]
  },
  methods: {
    initFiles() {
      this.files = this.insurancePolice.files.length ? this.insurancePolice.files : [new AppFile]
    },
    addFile() {
      this.files.push(new AppFile)
    },
    onClickPrev() {
      this.$emit('clickPrev')
    },
    async onClickNext() {
      if (!this.fileForm.validate()) {
        return
      }

      if(this.isDisableAllInputs) {
        this.$emit('clickNext')

        return
      }

      const result = await this.uploadPoliceFile(this.insurancePolice.id, this.files, this.dataFieldKeys.electronicSignature)

      if(result) {
        await this.updatePolice(this.insurancePolice.id, this.insurancePolice)

        await this.sendElectronicSignatureEmail(this.insurancePolice.id)

        await this.statusApplicationSubmitted()

        this.$emit('clickNext')
      }
    },
    async onClickApplicationAsPdf() {
      const nameData = await serverApi.insurance.getPdfName(this.$route.params.insuranceId, 'Antrag');

      await this.runGeneratePdf('application', nameData?.data?.name || 'Antrag.pdf')
    },
    async onClickInfoPackageAsPdf() {
      const fileName = `Infopaket VH – ${this.$route.params.insuranceId}.zip`;

      await this.runGeneratePdf('zip', fileName);

      this.setIsApplicationAsPdfBtnDisabled(false);
    },
    async onClickInfoPackageAsEmail() {
      if (!this.emailForm.validate()) {
        return
      }

      this.insurancePolice.data.managerEmail = this.managerEmail
      this.insurancePolice.data.infoPackEmail = this.infoPackEmail

      this.saveDateField(dateService.getNowDate(SQL_FORMAT))

      const status = await this.sendInfoPackEmail(this.insurancePolice.id, this.infoPackEmail)

      if (status) {
        notificationService.success("Email was send successfully")

        await this.updatePolice(this.insurancePolice.id, this.insurancePolice)
      }
    },
    setIsApplicationAsPdfBtnDisabled(val) {
      this.isApplicationAsPdfBtnDisabled = val
    },
    async sendInfoPackEmail(insurancePoliceId, email) {
      const response = await this.$store.dispatch('insuranceModule/insurancePoliceModule/sendInfoPackEmail', {
            insurancePoliceId, email
          }
      );

      if (response.success) {
        notificationService.success('Email sent successfully')
      } else {
        notificationService.error(response.message)
      }
    },
    saveField(val, key) {
      this.$store.commit('insuranceModule/insurancePoliceModule/setDataField', {key, val});
    },
    async updatePolice(insurancePoliceId, data) {
      await this.$store.dispatch('insuranceModule/insurancePoliceModule/updateInsurancePolice', {
            insurancePoliceId, data
          }
      );
    },
    onSelectFile(file) {
      this.file = file
    },
    saveDateField(val) {
      this.$store.commit('insuranceModule/insurancePoliceModule/setDataField', {
        key: this.dataFieldKeys.electronicSignatureSubmittedAt,
        val: val
      });
    },
    async runGeneratePdf(pdf, filename) {
      const {data} = await serverApi.insurance.runGeneratePdf(this.$route.params.insuranceId, pdf)
      generateDownloadLink(data, filename)
    },
    async uploadPoliceFile(insurancePoliceId, files, fileKey) {
      return await this.$store.dispatch('insuranceModule/insurancePoliceModule/updateInsurancePoliceFile', {
            insurancePoliceId,
            files: files,
            fileKey
          }
      );
    },
    async sendElectronicSignatureEmail(insurancePoliceId) {
      const response = await this.$store.dispatch('insuranceModule/insurancePoliceModule/sendElectronicSignatureEmail', {
            insurancePoliceId
          }
      );

      if (response.success) {
        notificationService.success('Email sent successfully')
      } else {
        notificationService.error(response.message)
      }
    },
    changeNextBtnName() {
      if (this.isInsuranceFinishedAt) {
        this.nextBtnName = STEP3_OPTIONS_BUTTON_NAMES.next

        return
      }

      this.nextBtnName = STEP3_OPTIONS_BUTTON_NAMES.lock
    },
    changeValidation() {
      if (this.isDisableAllInputs) {
        this.fileValidation = {}
      }
    },
    onClickRemoveDuplicate(index) {
      this.files.splice(index, 1);
    },
    isDuplicate(index) {
      return index > 0;
    },
    async statusApplicationSubmitted() {
      await this.$store.dispatch('insuranceModule/statusApplicationSubmitted')
    },
    onChangeSendEmailCheckboxValue(value) {
      this.sendEmailCheckboxValue = value + '';
    },
  },
}
</script>
