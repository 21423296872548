import {dateService} from "@/utils/services/date-service.js";
import {DateFieldValidation} from "@/utils/types/date-field-validation";
import {builderElementService} from "@/utils/services/builder-element-service";

export const required = (val, message = 'Pflichtfeld') => {
    return (val !== null && val !== undefined && val !== '') || message
}

export const equalToElementValue = (val, elementId, allBuilderElements, message = 'Error') => {
    const [compareElement] = builderElementService.findAllElementsByParameters(allBuilderElements, 'id', elementId);

    return (val === compareElement?.value) || message
}

export const requiredCheckbox = (val, message = 'Pflichtfeld') => {
    return (val !== null && val !== undefined && val !== '' && val !== '0' && val !== 0 && val !== false) || message
}

export const email = (val, message = "Bitte tragen Sie eine gültige E-Mail-Adresse ein.") => {
    const regex = new RegExp('([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\\.[a-zA-Z0-9_-]+)');

    return (required(val, message) && regex.test(val)) || message
}

// allow specific symbols
export const specificEmail = (val, message = "Bitte tragen Sie eine gültige E-Mail-Adresse ein.") => {
    const regex = new RegExp('(^[a-zA-Z0-9\\\\&\\\\"._-]+@[a-zA-Z0-9\\\\&\\\\"._-]+\\.[a-zA-Z0-9\\\\&\\\\"_-]+$)');

    return (required(val, message) && regex.test(val)) || message
}

export const maxLength = (val, params, message = "Max length for this field is ") => {
    const valString = val ? val.toString() : '';

    return valString.length < params.length || message + " " + params.length
}

export const maxLengthIncluding = (val, params, message = "Max length for this field is ") => {
    const valString = val ? val.toString() : '';

    return valString.length <= params.length || message;
}

export const isJson = (val, message = "Invalid Json Format") => {
    try {
        JSON.parse(val);

        return true;
    } catch {
        return message;
    }
}

export const isNumber = (val, message = 'Value should be a number') => {
    if (val === '' || val === null || val === undefined) {
        return true;
    }

    return !isNaN(val) || message;
}

export const minValue = (val, minValue, message = "Value should be higher") => {
    if (isNaN(val) || val === '') {
        return true;
    }

    return val >= minValue || message;
}

export const maxValue = (val, maxValue, message = "Value should be lower") => {
    if (isNaN(val) || val === '') {
        return true;
    }

    return val <= maxValue || message;
}

export const checkDateFieldValidation = (val, dateFieldValidation, allBuilderElements) => {
    const ruleDate = getRuleDate(dateFieldValidation, allBuilderElements);

    if(dateFieldValidation.isAddCondition()) {
        return dateService.isAfterOrEqual(ruleDate, val) || dateFieldValidation.message;
    }

    return dateService.isAfter(val, ruleDate) || dateFieldValidation.message;
}

export const checkDateFieldPopupValidation = (val, range) => {
    return isInDateFieldRange(val, range);
}

export const isInDateFieldRange = (val, range, allBuilderElements) => {
    const startDate = range.start && range.start.useCondition ?
        new DateFieldValidation(range.start) :
        null;

    const endDate = range.end && range.end.useCondition ?
        new DateFieldValidation(range.end) :
        null;

    const ruleDateStart = startDate ? getRuleDate(startDate, allBuilderElements) : null;
    const ruleDateEnd = endDate ? getRuleDate(endDate, allBuilderElements) : null;

    const startPeriodCondition = ruleDateStart ? dateService.isAfterOrEqual(val, ruleDateStart) : true;
    const endPeriodCondition = ruleDateEnd ? dateService.isBeforeOrEqual(val, ruleDateEnd) : true;

    return startPeriodCondition && endPeriodCondition;
}

export const checkDateFieldRangeValidation = (val, range, allBuilderElements) => {
    return isInDateFieldRange(val, range, allBuilderElements) || range.message;
}

export const getRuleDate = (dateFieldValidation, allBuilderElements) => {
    const compareDate = setCompareValue(dateFieldValidation, allBuilderElements);

    if(dateFieldValidation.isAddCondition()) {
        //add days|months|years to compareDate
        return dateService.addPeriod(compareDate, dateFieldValidation.period, dateFieldValidation.amount);
    }
    //substract days|months|years from currentValue
    return dateService.subPeriod(compareDate, dateFieldValidation.period, dateFieldValidation.amount);
}

export const duplicateValue = (val, compareVal, message = 'Value should be repeat') => {
    if (val === compareVal) {
        return true;
    }

    return message;
}

export const phone = (val, message = 'Bitte tragen Sie eine gültige Telefonnummer ein.') => {
    return (val[0] === '+' || Number(val[0]) === 0) || message
}

export const validateBySumElements = (val, elementNames,  operation, targetValue, allBuilderElements, passiveElement, message = 'Pflichtfeld') => {
    const builderElements = builderElementService.findAllElementsByParameters(allBuilderElements, 'name', elementNames)

    const sum = builderElements.reduce(
        (total, currentValue) => total + Number(currentValue.value),
        0
    );

    if (targetValue === 'sumElements') {
        targetValue = +passiveElement.value
    }

    let result;

    switch (operation) {
        case '>' : result = sum > +targetValue; break;
        case '<' : result = sum < +targetValue; break;
        case '>=' : result = sum >= +targetValue; break;
        case '<=' : result = sum <= +targetValue; break;
        default : result = sum === +targetValue; break;
    }

   return result || message;
}

const setCompareValue = (dateFieldValidation, allBuilderElements) => {
    if (dateFieldValidation.isCustomValue()) {
        return dateFieldValidation.value;
    }

    if (dateFieldValidation.isValidationByField()) {
        const [targetElement] = builderElementService.findAllElementsByParameters(
            allBuilderElements,
            'name',
            [dateFieldValidation.value]);

        if (targetElement) {
            return targetElement.value;
        }

        throw new Error(`Not existing builder element ${dateFieldValidation.value} for date validation`);
    }

    return dateService.getCurrentDate();
}

export const validateBySumMoreElements = (val, elementNames,  operation, targetValue, allBuilderElements, passiveElement, message = 'Pflichtfeld') => {
    const builderElements = builderElementService.findAllElementsByParameters(allBuilderElements, 'name', elementNames)

    const sum = builderElements.reduce(
      (total, currentValue) => total + Number(currentValue.value),
      0
    );

    if (targetValue === 'sumElements') {
        targetValue = +passiveElement.value
    }

    let result;

    switch (operation) {
        case '>' : result = sum > +targetValue; break;
        case '>=' : result = sum >= +targetValue; break;
        default : result = sum === +targetValue; break;
    }

    return result || message;
}

export const minLength = (val, params, message = "Min length for this field is ") => {
    const valString = val ? val.toString() : '';

    return valString.length > params.length || message + " " + params.length
}

export const specialSymbols = (val, {symbols}) => {
    const valString = val ? val.toString() : '';

    const formatedSymbols = symbols.map(symbol => `"${symbol}"`);

    for (let i = 0; i < symbols.length; i++) {
        if (valString.includes(symbols[i])) {
            return "Bitte keine Zeichen " + formatedSymbols.join(' und ') + " verwenden";
        }
    }

    return true;
}

export const allowSpecialSymbols = (val, message = "Bitte tragen Sie eine gültige") => {
    if (!val) {
        return true
    }

    const regex = new RegExp('(^[a-zA-ZäöüÄÖÜß0-9\\\\&/., -]+$)');

    return regex.test(val) || message
}